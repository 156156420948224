<template>
  <div>
    <Slider />
    <Description section="corporate" />
    <Strengths :strengths="strengths" title="Key Services" />
    <Slogan />
    <TestimonialsPage />
  </div>
</template>

<script>
export default {
    name: "corporate",
    components: {
       Slider: () => import('./sections/sliders/corporate'),
       Description: () => import('./sections/description'),
       Strengths: () => import('./sections/strengths'),
       Slogan: () => import('./sections/slogan'),
       TestimonialsPage: () => import('../home/testimonials'),
    },
     computed: {
       strengths () {
         const data = [
              {
                    image: 'Pie_chart.png',
                    title: 'APIs',
                    text: 'Through the click of a button or API call, you can quickly execute various transaction types, accurately within the shortest time possible.',
                    color: '#d29f2a',
                },
                 {
                    image: 'Pie_chart.png',
                    title: 'Reporting & Analytics',
                    text: 'Use Zamupay for your payments, financial analytics, reporting, reconciliation, tracking and auditing of your transactions.',
                    color: '#d29f2a',
                },
              
         ];
         return data;
       }

    },

}
</script>

<style>

</style>